import React from 'react';

import Header from '../components/header';
import { Container, ContentContainer } from '../components/layoutComponents';
import SEO from "../components/seo";
import { keywords } from '../constants/Keywords';

const Contact = ({ location }) => {
    return (
        <>
            <SEO title="Gallery" keywords={keywords} />
            <Header path={location.pathname} />
            <Container>
                <ContentContainer padding="8rem 0">
                    <h1>
                        Gallery
                    </h1>
                    <h2>Coming Soon!</h2>
                </ContentContainer>
            </Container>
        </>
    )
}

export default Contact;